import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { FaqPageTemplate } from '@components';

const FaqPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <FaqPageTemplate {...frontmatter} />
  );
};

FaqPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }),
};

export default FaqPage;

export const faqPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        hero {
          title
          markdown
        }
        seo {
          metaTitle
          metaDescription
        }
        categories {
          title
          color
          items {
            question
            answer
          }
        }
      }
    }
  }
`;
